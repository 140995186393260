var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "재해 정보" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "발생형태",
                    value: _vm.accData.accidentTypeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "재해유형",
                    value: _vm.accData.occurrenceName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "재해일시",
                    value: _vm.accData.occurrenceDate,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-12 col-lg-4" },
              [
                _c("c-label-text", {
                  attrs: { title: "재해명", value: _vm.accData.accidentName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "재해장소",
                    value: _vm.accData.occurrenceLocation,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "발생부서",
                    value: _vm.accData.occurrenceDeptName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "공정", value: _vm.accData.processName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사고번호(품번)",
                    value: _vm.accData.accidentNo,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "사업장", value: _vm.accData.plantName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "가해자",
                    value: _vm.accData.attackerUserName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "목격자",
                    value: _vm.accData.witnessUserName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-6" },
              [
                _c("c-label-text", {
                  attrs: { title: "기인물", value: _vm.accData.openning },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-6" },
              [
                _c("c-label-text", {
                  attrs: { title: "가해물", value: _vm.accData.injurious },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-6 col-lg-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "재해발생경위",
                    value: _vm.accData.overviewAccidentSituation,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [_c("c-upload", { attrs: { editable: false } })],
              1
            ),
          ]),
        ],
        2
      ),
      _c("br"),
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "사고자 정보" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사고자",
                    value: _vm.accData.accidentUserName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-4" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사고자 인적사항 (생년월일/직위/나이/성별/근속년수)",
                    value: _vm.companyInfo,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "기타 장해보상금(원)",
                    value: _vm.accData.wage,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "상해종류", value: _vm.accData.woundName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "상해부위(대)",
                    value: _vm.accData.woundFstName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "상해부위(중)",
                    value: _vm.accData.woundSecName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "과실여부", value: _vm.accData.fruitName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "경고장", value: _vm.accData.warningName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "요양기간(시작일 ~ 복귀일)",
                    value:
                      _vm.accData.accidentStartDate +
                      " ~ " +
                      _vm.accData.accidentEndDate,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "휴업일수(주말 제외)",
                    value: _vm.accData.nursingCount,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "병원", value: _vm.accData.hospitalName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "병원진료비(원)",
                    value: _vm.accData.hospitalCost,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-4" },
              [
                _c("c-label-text", {
                  attrs: { title: "진단명", value: _vm.accData.diagnosisName },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [_c("c-upload", { attrs: { editable: false } })],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }