<template>
  <div>
    <c-card title="재해 정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="발생형태" :value="accData.accidentTypeName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="재해유형" :value="accData.occurrenceName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="재해일시" :value="accData.occurrenceDate"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-12 col-lg-4">
          <c-label-text title="재해명" :value="accData.accidentName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="재해장소" :value="accData.occurrenceLocation"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="발생부서" :value="accData.occurrenceDeptName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="공정" :value="accData.processName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사고번호(품번)" :value="accData.accidentNo"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사업장" :value="accData.plantName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="가해자" :value="accData.attackerUserName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="목격자" :value="accData.witnessUserName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-6">
          <c-label-text title="기인물" :value="accData.openning"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-6">
          <c-label-text title="가해물" :value="accData.injurious"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-6">
          <c-label-text title="재해발생경위" :value="accData.overviewAccidentSituation"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-upload 
            :editable="false">
          </c-upload>
        </div>
      </template>
    </c-card>
    <br>
    <c-card title="사고자 정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사고자" :value="accData.accidentUserName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-4">
          <c-label-text title="사고자 인적사항 (생년월일/직위/나이/성별/근속년수)" :value="companyInfo"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="기타 장해보상금(원)" :value="accData.wage"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="상해종류" :value="accData.woundName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="상해부위(대)" :value="accData.woundFstName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="상해부위(중)" :value="accData.woundSecName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="과실여부" :value="accData.fruitName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="경고장" :value="accData.warningName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="요양기간(시작일 ~ 복귀일)" :value="accData.accidentStartDate + ' ~ ' + accData.accidentEndDate"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="휴업일수(주말 제외)" :value="accData.nursingCount"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="병원" :value="accData.hospitalName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="병원진료비(원)" :value="accData.hospitalCost"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-4">
          <c-label-text title="진단명" :value="accData.diagnosisName"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-upload 
            :editable="false">
          </c-upload>
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'accident-info-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      accData: {
        iimAccidentId: '',  // 공정사고 일련번호
        accidentTypeCd: null,  // 재해유형
        plantCd: '',  // 사업장 코드
        accidentNo: '',  // 사고번호
        accidentName: '',  // 사고명
        accidentStatusCd: null,  // 사고등록/접수/조사중/종결
        occurrenceDate: '',  // 발생일시
        occurrenceDeptCd: null,  // 발생부서
        occurrenceLocation: '',  // 발생장소
        accidentCause: '',  // 재해발생원인
        overviewAccidentSituation: '',  // 재해발생경위
        occurrenceCd: null,  // 발생형태코드
        openning: '',  // 기인물
        injurious: '',  // 가해물
        managerOpinion: '',  // 환경안전팀 의견
        woundCd: null,  // 상해종류
        woundFstCd: null, // 상해부위(대)
        woundSecCd: null,  // 상해부위(중)
        fruitCd: null,  // 과실여부
        warningCd: null,  // 경고장
        engineeringRecurrence: '',  // 재발방지대책(공학적)
        managementRecurrence: '',  // 재발방지대책(관리적)
        accidentUserId: '',  // 사고자
        accidentEnterDate: '',
        accidentBirthDate: '',  
        accidentMobileNo: '',  
        accidentEmpNo: '',  
        accidentSpotName: '',  
        accidentStartDate: '',  // 사고시작일
        accidentEndDate: '',  
        accidentAge: '', 
        accidentSexName: '',  
        accidentLongevityCount: '', 
        attackerUserId: '',  // 가해자
        witnessUserId: '',  // 목격자
        hospitalName: '',  // 병원
        hospitalCost: '',  // 병원진료비
        nursingCount: 0,
        wage: '',  // 임금
        diagnosisName: '',  // 진단명
        jobName: '',  // 단위작업명
        accidentMode: '',  // 모드
        accidentLine: '',  // 라인
        processCd: '',  // 라인
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      editable: true,
      getUrl: '',
    };
  },
  computed: {
    companyInfo() {
      return this.accData.accidentBirthDate + '/' + this.accData.accidentSpotName + '/' + this.accData.accidentAge + '/' + this.accData.accidentSexName + '/' + this.accData.accidentLongevityCount;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.accData, _result.data);
        this.$emit('setAccidentName', this.accData.accidentName)
      },);
    },
  }
};
</script>
